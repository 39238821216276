import { CTAButton } from '@src/components/ui/CTAButton'
import { useRouter } from 'next/router'

const Custom404 = () => {
  const router = useRouter()

  return (
    <div className="tw-absolute tw-top-[40%] tw-flex tw-w-full tw-transform tw-flex-col tw-items-center tw-justify-center tw-px-24">
      <span className="subtitle2 tw-text-center tw-text-16 tw-font-semibold tw-text-text-primary">
        원하는 페이지를 찾을 수 없습니다.
        <br />
        홈으로 이동해 다시 진행해 주세요.
      </span>
      <CTAButton
        buttonStyle="fourth"
        buttonSize="large"
        fontSize="large"
        buttonClassName="tw-w-full tw-mt-16"
        onClick={() => router.replace('/')}>
        홈으로 가기
      </CTAButton>
    </div>
  )
}

export default Custom404
